import { useState } from "react";
import ReactDOM from "react-dom";
import { IoClose } from "react-icons/io5";
import { IoCopyOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const ChangeNameModal = ({ showmodal, setshowmodal }) => {
  const { t, i18n } = useTranslation();
  return ReactDOM.createPortal(
    <div className="w-screen h-screen flex items-center z-40 fixed bg-[#00000086] left-0 top-0 justify-center">
      <div className="rounded-md h-[150px] p-2 w-[90%] md:w-[450px] animate__animated animate__fadeInUp  bg-white shadow-md">
        <div className="hidden items-center justify-between">
          <div
            className="flex items-center justify-center"
            onClick={() => setshowmodal(!showmodal)}
          >
            <IoClose size={22} />
          </div>
        </div>
        {/* <hr className="border-[0.8px] border-[#e8effa]  my-1" /> */}

        <div className="flex items-center flex-col justify-center">
          <h3 className="font-[600] mt-3">Great News</h3>
          <h3 className="text-center text-1xl mt-1">
            We are pleased to announce that{" "}
            <span className="font-bold">Spiral</span> has moved to{" "}
          </h3>
          {/* via-green-500 */}
          <div class="text-[30px] font-bold bg-gradient-to-r from-black  to-[dodgerblue] inline-block text-transparent bg-clip-text">
            Chanels io
          </div>
          {/* <h1 className="text-2xl mt-3 font-bold">Chanels io</h1> */}
        </div>

        <div className="mt-4 hidden">
          <div className="">
            <div className="font-[600]">
              {/* Email */}
              {/* {t("contact.email")} */}
            </div>
            <div className="flex mt-1 items-center border-[1px] rounded-md p-2 border-grey-800 justify-between">
              <a
                href="mailto:hello@spiral.systems"
                className="underline text-[#3B82F6]"
              >
                hello@spiral.systems
              </a>
              <div
                onClick={() => {
                  navigator.clipboard.writeText("hello@spiral.systems");
                  window.alert("copied");
                }}
                className="items-center justify-center"
              >
                <IoCopyOutline />
              </div>
            </div>
          </div>
          <div className="mt-2 mb-6">
            <div className="font-[600]">
              {t("contact.address")}
              {/* Address */}
            </div>
            <div className="flex mt-1items-center border-[1px] rounded-md p-2 border-grey-800 ">
              <div className="">
                Spiral Systems,
                <br /> Industry Innovation Lab
                <br /> Carnegie Mellon University Africa <br /> Kigali, Rwanda
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("contactmodal")
  );
};

export default ChangeNameModal;
