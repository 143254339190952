import React, { Suspense } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Landing from "./screens/Landing";
import Features from "./screens/Features";
import Sections from "./screens/Sections";
import Blog from "./screens/Blog";
import BlogDetails from "./screens/Blog/BlogDetails";
import NewFeature from "./screens/NewFeatures/NewFeature";
import Developer from "./screens/Developer";

function App() {
  return (
    <Suspense
      fallback={
        <div className="flex items-center w-screen h-screen justify-center">
          <div className="text-[#080820]">Loading resource...</div>
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<Landing />} />
        {/* <Route path="/features" element={<Features />} /> */}
        <Route path="/features" element={<Developer />} />
        {/* <Route path="/developer" element={<NewFeature />} /> */}
        <Route path="/services" element={<Sections />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogDetails />} />
      </Routes>
    </Suspense>
  );
}

export default App;
