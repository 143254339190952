import { v4 as uuidv4 } from "uuid";
import Tools from "../../assets/png/tool.png";
import Server from "../../assets/png/server.png";
import Theft from "../../assets/png/theft.png";
import Good from "../../assets/png/good.png";
import Money from "../../assets/png/money.png";
import Computer from "../../assets/png/computer.png";
export const sectionData = [
  {
    id: uuidv4(),
    title: "Warranty Type (Advance Replacement)",
    desc: `You will receive a spare unit for every 100 units you purchase. In case there is a defect during operations, Note that this spare unit is our property and cannot be integrated`,
    img: <img src={Good} className=" " alt="" />,
  },
  {
    id: uuidv4(),
    title: "Repairability",
    desc: `Modularity reduces cost drastically
    Insight.`,
    img: <img src={Tools} className=" " alt="" />,
  },
  {
    id: uuidv4(),
    title: "Theft Detection:",
    desc: `within the hour detection of energy theft or other NTLosses`,
    img: <img src={Theft} className=" " alt="" />,
  },
  {
    id: uuidv4(),
    title: "Affordability",
    desc: ``,
    img: <img src={Money} className=" " alt="" />,
  },
  {
    id: uuidv4(),
    title: "Continuous Development/Integration: ",
    desc: `We are a software and data company. Based on community process, bi-annual major hardware/firmware/software upgrades. Minor updates happen too.`,
    img: <img src={Server} className=" " alt="" />,
  },
  {
    id: uuidv4(),
    title: "Advance Software: ",
    desc: `Everything you need to manage customers, revenue, load/customer segmentation.`,
    img: <img src={Computer} className=" " alt="" />,
  },
  {
    id: uuidv4(),
    title: "Predictable costs:",
    desc: `Regardless of the advances we make, and the benefits this brings you, whatever fees (think SaaS) you pay on a specific item or product class in the first year will not increase for the first 5 years even if there are upward price reviews for that class of invoice.`,
    img: <img src={Money} className=" " alt="" />,
  },
];
